import React from 'react'
import classNames from 'classnames'

const SectionWithColumnLayout = ({ children, contentClassName, title }) => {
  const classes = classNames('section__content', contentClassName)

  return (
    <div className='section section--column-layout'>
      <div>
        {/* Wrapper needed to get bottom border correctly */}
        <h1 className='section__heading'>{title}</h1>
      </div>
      <div className={classes}>{children}</div>
    </div>
  )
}

export default SectionWithColumnLayout
