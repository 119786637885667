import React from 'react'
import { graphql } from 'gatsby'

import InfoSection from 'components/InfoSection'
import Layout from 'components/Layout'
import SectionWithColumnLayout from 'components/SectionWithColumnLayout'

const OmOss = ({ data }) => (
  <Layout
    pageTitle='Om oss'
    headerBackgroundImage={data.header.childImageSharp.gatsbyImageData}
    headerBackgroundPosition='30% 60%'
  >
    <oma-grid-row contentAlignedWithGrid='true'>
      <SectionWithColumnLayout title='Om oss'>
        <h2 className='heading'>
          Välkommen till oss på Ertan - vår vision är att göra våra kunder till
          bättre företagare.
        </h2>
        <p className='section__text'>
          Ertan startades 2006 och har sedan dess vuxit till en väletablerad
          partner till små och medel- stora företag i och runt om Borås. Våra
          kunderna finns inom många olika branscher, t ex åkeribranschen,
          entreprenad, friskvård och tjänstesektorn och alla har en sak
          gemensamt, – behovet av en redovisningsbyrå med hög kompetens och
          engagerade medaretare.
        </p>
        <p className='section__text'>
          Vi gillar dessutom när vi kan förenkla och digitalisera olika flöden.
          Vi jobbar mycket med Fortnox som är en molnbaserad plattform för
          ekonomiadministration, för småföretag, redovisningsbyråer, föreningar
          och skolor. Väldigt smidigt för alla!
        </p>
        <h2 className='section__sub-heading'>
          Ni slipper anställa en egen ekonom och här finns tid och pengar att
          spara!
        </h2>
        <p className='section__text'>
          Ni kan fokusera på det ni är bra på och vi gör det vi är bra på,
          nämligen sköta er ekonomiska redovisning, stor som liten. Det vi
          förväntar oss av våra kunder är att de håller ordning på alla dokument
          som rör verksamheten och att vi får all den information som är
          behövlig till uppdraget. Enkelt och ekonomiskt!
        </p>
        <p className='section__text'>
          <strong>
            Vill du veta mer och träffa oss, hör av dig så bokar vi ett möte.
            Ring <oma-link to='+4633414535' /> eller maila{' '}
            <oma-link to='info@ertan.se' />.
          </strong>
        </p>
      </SectionWithColumnLayout>
    </oma-grid-row>
    <InfoSection />
  </Layout>
)

export const query = graphql`
  {
    header: file(relativePath: { eq: "om-oss.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: FULL_WIDTH)
      }
    }
  }
`

export default OmOss
