import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { BgImage } from 'gbimage-bridge'

const InfoSection = () => {
  const { smallBackground, wideBackground } = useStaticQuery(graphql`
    {
      smallBackground: file(relativePath: { eq: "nature.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 900
            quality: 70
            transformOptions: {
              duotone: { highlight: "#ffffff", shadow: "#ffffff", opacity: 50 }
            }
            layout: CONSTRAINED
          )
        }
      }
      wideBackground: file(relativePath: { eq: "nature.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 70
            transformOptions: {
              duotone: { highlight: "#ffffff", shadow: "#ffffff", opacity: 50 }
            }
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  const imageSources = [
    smallBackground.childImageSharp.gatsbyImageData,
    {
      ...wideBackground.childImageSharp.gatsbyImageData,
      media: '(min-width: 900px)',
    },
  ]

  return (
    <oma-grid-row fullWidth='true' class='row'>
      <section className='info-section'>
        <BgImage image={imageSources} style={{ backgroundPosition: '0 0' }}>
          <div className='info-section__column-wrapper'>
            <div>
              <h2 className='info-section__heading'>
                En lösning <br />
                för alla behov
              </h2>
            </div>
            <div className='info-section__content'>
              <p>
                <i>
                  Bokföring, fakturering, reskontrahantering, momsredovisning,
                  lönehantering, kvitton och resor, bokslut & årsredovisningar,
                  deklarationer, bolagsbildning, ekonomisk rådgivning,
                  betalningsservice. Ja, listan är lång och vi hjälper till med
                  allt eller de delar ni har behöver hjälp med. När och var ni
                  vill, enkelt och ekonomiskt!
                </i>
              </p>
            </div>
          </div>
        </BgImage>
      </section>
    </oma-grid-row>
  )
}

export default InfoSection
